import colors from './colors';
import fonts from './fonts';
import spacing from './spacing';
import borders from './borders';
import media from './mediaQueries';
import zIndex from './zIndex';

const theme = {
  colors,
  fonts,
  spacing,
  borders,
  media,
  zIndex,
};

export default theme;
