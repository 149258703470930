'use client'

import { useEffect, useState, useCallback, useMemo } from 'react'
import { Switch } from '@/components/primitives/switch'
import { Separator } from '@/components/primitives/separator'
import { useAuth } from '@clerk/nextjs'
import Knock, { SetPreferencesProperties, PreferenceSet } from '@knocklabs/client'

type NotificationPreference = {
  id: string // unique identifier, can be any value
  title: string // title of the setting in UI
  description: string // description of the setting in UI
  alwaysOn?: boolean // if true, the setting cannot be toggled and the switch is not rendered
  enabled: boolean // if true, the setting is enabled
  knockSection: string // the section in the Knock preferences object
  knockKey: string // the key in the Knock preferences object
}

/**
 * Converts a NotificationPreference[] to a SetPreferencesProperties object
 * that can be passed to Knock's setPreferences method.
 * @param settings NotificationPreference[]
 * @returns SetPreferencesProperties
 */
function convertLocalPrefsToKnockPrefs(settings: NotificationPreference[]): SetPreferencesProperties {
  return settings.reduce((prefs, { enabled, alwaysOn, knockSection, knockKey }) => {
    const value = enabled || alwaysOn || false
    // @ts-expect-error FIXME TS wants this to be passed as a WorkflowPreferences or ChannelTypePreferences. Refactor this code.
    prefs[knockSection] ??= {}
    // @ts-expect-error FIXME TS wants this to be passed as a WorkflowPreferences or ChannelTypePreferences. Refactor this code.
    prefs[knockSection][knockKey] = value
    return prefs
  }, {} as SetPreferencesProperties)
}

const initialPreferences: NotificationPreference[] = [
  {
    id: 'inbox',
    title: 'Inbox',
    description: 'Notifications are always sent to your Offerwell inbox.',
    alwaysOn: true,
    enabled: true,
    knockSection: 'channel_types',
    knockKey: 'in_app_feed',
  },
  {
    id: 'email',
    title: 'Email',
    description: 'Messages are sent to the email associated with the workspace.',
    enabled: true,
    knockSection: 'channel_types',
    knockKey: 'email',
  },
  {
    id: 'sms',
    title: 'SMS',
    description: 'Messages are sent as text messages to your phone.',
    enabled: false,
    knockSection: 'channel_types',
    knockKey: 'sms',
  },
  {
    id: 'push',
    title: 'Push',
    description: 'Notifications are sent as push notifications to your devices.',
    enabled: false,
    knockSection: 'channel_types',
    knockKey: 'push',
  },
  {
    id: 'listings',
    title: 'Listing updates',
    description: 'Get notified about changes to your listings.',
    enabled: true,
    knockSection: 'categories',
    knockKey: 'listings',
  },
  {
    id: 'offers',
    title: 'Offer updates',
    description: 'Receive updates on offers for your properties.',
    enabled: true,
    knockSection: 'categories',
    knockKey: 'offers',
  },
  {
    id: 'showings',
    title: 'Showings updates',
    description: 'Stay informed about scheduled and completed showings.',
    enabled: true,
    knockSection: 'categories',
    knockKey: 'showings',
  },
  {
    id: 'reminders',
    title: 'Send reminders',
    description: 'Receive reminders for upcoming tasks and deadlines.',
    enabled: true,
    knockSection: 'workflows',
    knockKey: 'reminders',
  },
  {
    id: 'team',
    title: 'Team updates',
    description: 'Get notified about changes and activities within your team.',
    enabled: true,
    knockSection: 'workflows',
    knockKey: 'team',
  },
]

export default function NotificationSettingsPage() {

  const { userId } = useAuth()

  const knockClient = useMemo(() => new Knock(process.env.NEXT_PUBLIC_KNOCK_PUBLIC_KEY!), [])
  useEffect(() => {
    knockClient.authenticate(userId!)
  }, [knockClient, userId])

  const [localPreferences, setLocalPreferences] = useState(initialPreferences)
  const [firstLoad, setFirstLoad] = useState(true)

  const mergeAndSetLocalPreferences = useCallback((preferences: PreferenceSet) => {
    setLocalPreferences(prev => // Get the current local prefs
      prev.map(pref => { // Map over each pref
        // @ts-expect-error FIXME TS wants this to be passed as a WorkflowPreferences or ChannelTypePreferences. Refactor this code.
        const value = preferences[pref.knockSection][pref.knockKey] // Find the value in the Knock prefset, such as `categories.listings = true`
        return { ...pref, enabled: value } // Return the local pref with true/false toggled
      })
    )
  }, [])

  const togglePreference = useCallback(async (id: string) => {
    // Find the preference that matches the toggle, and merge in it's enabled state.
    const mergedPrefs = localPreferences.map(pref =>
      pref.id === id
        ? { ...pref, enabled: !pref.enabled, ...(pref.alwaysOn ? { alwaysOn: pref.alwaysOn } : {}) }
        : pref
    )

    const prefUpdate = convertLocalPrefsToKnockPrefs(mergedPrefs)
    const newPrefs = await knockClient.user.setPreferences(prefUpdate)
    mergeAndSetLocalPreferences(newPrefs)
  }, [knockClient, localPreferences, mergeAndSetLocalPreferences])

  useEffect(() => {
    async function fetchPreferences() {
      const preferences = await knockClient.user.getPreferences() // fetch a prefset
      mergeAndSetLocalPreferences(preferences) // merge the prefset with the local prefs
      setFirstLoad(false) // on first load, enable the toggles
    }
    fetchPreferences()
  }, [knockClient, mergeAndSetLocalPreferences])

  const renderSection = (sectionSettings: typeof initialPreferences) => (
    <div className="space-y-4">
      {sectionSettings.map(setting => (
        <div key={setting.id} className="flex items-center space-x-4">
          <div className="flex-grow">
            <h3 className="text-sm font-semibold">{setting.title}</h3>
            <p className="text-xs text-gray-500">{setting.description}</p>
          </div>
          {setting.alwaysOn ? (
            <div className="w-[42px]"></div>
          ) : (
            <Switch
              disabled={firstLoad}
              checked={setting.enabled}
              onCheckedChange={() => togglePreference(setting.id)}
            />
          )}
        </div>
      ))}
    </div>
  )

  return (
    <div className="w-full max-w-3xl space-y-6">
      <h2 className="text-base font-bold">Notifications</h2>
      {renderSection(localPreferences.slice(0, 4))}
      <Separator className="my-4" />
      {renderSection(localPreferences.slice(4, 7))}
      <Separator className="my-4" />
      {renderSection(localPreferences.slice(7))}
    </div>
  )
}
