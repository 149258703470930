import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/Tooltip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/Dropdown';
import Button from '@/components/ui/Button';
import Dashboard from '@/components/ui/icons/Dashboard';
import {
  Archive,
  Buildings,
  Calendar,
  Files,
  MagnifyingGlass,
} from '@phosphor-icons/react/dist/ssr';
import { BellRing } from 'lucide-react'
import NextLink from 'next/link';
import styles from './styles.module.css';

import { UserButton, useAuth } from '@clerk/nextjs'

import {
  KnockFeedProvider,
  KnockProvider,
  NotificationFeedPopover,
  NotificationIconButton,
} from '@knocklabs/react'

import { useState, useRef } from 'react'

import NotificationSettingsPage from '@/components/v0/NotificationSettingsPage'

const DotIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
    </svg>
  )
}

export default function UserNav() {

  const { userId } = useAuth()
  const [isVisible, setIsVisible] = useState(false)
  const notificationButtonRef = useRef(null)

  return (
    <div className={styles.userNav}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="primary_mono_light"
              aria-label="Find a listing"
              isIconOnly
              asChild>
              <NextLink href="/">
                <MagnifyingGlass />
              </NextLink>
            </Button>
          </TooltipTrigger>
          <TooltipContent>Find a listing</TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DropdownMenu modal={false}>
        <TooltipProvider>
          <Tooltip>
            <DropdownMenuTrigger asChild>
              <TooltipTrigger asChild>
                <Button
                  variant="primary_mono_light"
                  aria-label="Dashboard"
                  isIconOnly>
                  <Dashboard />
                </Button>
              </TooltipTrigger>
            </DropdownMenuTrigger>
            <TooltipContent>Dashboard</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <DropdownMenuContent onCloseAutoFocus={(e) => e.preventDefault()}>
          <DropdownMenuGroup>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/dashboard?tab=listings">
                <Buildings size={20} /> My Listings
              </NextLink>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/dashboard?tab=offers">
                <Files size={20} />
                My Offers
              </NextLink>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/dashboard?tab=showings">
                <Calendar size={20} /> My Showings
              </NextLink>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <NextLink href="/agent/archive">
                <Archive size={20} /> Archive
              </NextLink>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <KnockProvider apiKey={process.env.NEXT_PUBLIC_KNOCK_PUBLIC_KEY || ''} userId={userId || ''}>
        <KnockFeedProvider feedId={process.env.NEXT_PUBLIC_KNOCK_FEED_KEY || ''}>
            <NotificationIconButton
              badgeCountType="unread"
              ref={notificationButtonRef}
              onClick={(e) => setIsVisible(!isVisible)}
            />
            <NotificationFeedPopover
              buttonRef={notificationButtonRef}
              isVisible={isVisible}
              onClose={() => setIsVisible(false)}
            />

        </KnockFeedProvider>
      </KnockProvider>

      <UserButton appearance={{
        elements: { avatarBox: styles.customClerkAvatar },
      }}>
        <UserButton.UserProfilePage label="Notifications" labelIcon={<BellRing size={16} />} url="notifications">
          <NotificationSettingsPage />
        </UserButton.UserProfilePage>
        <UserButton.MenuItems>
          <UserButton.Link
            label="Settings"
            labelIcon={<DotIcon />}
            href={`/agent/settings?active=general`}
          />
        </UserButton.MenuItems>
      </UserButton>

    </div>
  )
}
